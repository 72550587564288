import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Inicio',
    icon: 'home-outline',
    link: '/pages/dashboard',
    data: { roles: ['USUARIO', 'INSPECTOR', 'ADMIN'] },
    home: true,
  },
  {
    title: 'Catálogos',
    icon: 'folder-outline',
    link: '/pages/catalogos',
    data: { },
    children: [
      { title: 'Productos', link: '/pages/catalogos/productos', data: { } },
      { title: 'Categoría de productos', link: '/pages/catalogos/categoria-productos', data: { } },
      { title: 'Unidades de medida', link: '/pages/catalogos/unidades-peso', data: { } },
      { title: 'Producto Embalaje', link: '/pages/catalogos/producto-embalaje', data: { } },
      { title: 'Peso', link: '/pages/catalogos/peso', data: { } },
      { title: 'Actividad económica', link: '/pages/catalogos/actividad-economica', data: { } },
      { title: 'Tipos de persona', link: '/pages/catalogos/tipos-persona', data: { } },
      { title: 'Control tarifario', link: '/pages/catalogos/control-tarifario', data: { } },
      { title: 'Transportes', link: '/pages/catalogos/transportes', data: { } },
      { title: 'Tipo de transporte', link: '/pages/catalogos/tipo-transporte', data: { } },
      { title: 'Cantones', link: '/pages/catalogos/ciudades', data: { } },
      { title: 'Lugar de origen', link: '/pages/catalogos/lugar-origen', data: { } },
      { title: 'Lugar de destino', link: '/pages/catalogos/lugar-destino', data: { } },
      { title: 'Oficinas de gestión', link: '/pages/catalogos/oficina-gestion', data: { } },
      { title: 'Barcos', link: '/pages/catalogos/barcos', data: { } },
      { title: 'Cargos', link: '/pages/catalogos/cargos', data: { } },
      { title: 'Forma de Pago', link: '/pages/catalogos/forma-pago', data: { } },
    ],
  },
  {
    title: 'Inspección',
    icon: 'search-outline',
    link: '/pages/inspeccion',
    data: { },
    children: [
      { title: 'Origen', link: '/pages/inspeccion/origen-list', data: { } },
      { title: 'Destino', link: '/pages/inspeccion/destino-list', data: { } },
      { title: 'Perfil Riesgo', link: '/pages/inspeccion/perfil-riesgo', data: { } },
    ],
  },
  {
    title: 'Trámites',
    icon: 'file-text-outline',
    link: '/pages/tramites',
    data: { },
    children: [
      { title: 'Declaración', link: '/pages/tramites/declaracion', data: { } },
      { title: 'En tramite', link: '/pages/tramites/tramites-list', data: { } },
      { title: 'Anular Trámites', link: '/pages/tramites/tramites-list-anular', data: { } },
      { title: 'Confirmar Pago', link: '/pages/tramites/tramites-list-pago', data: { } },
      { title: 'Listar Todos', link: '/pages/tramites/tramites-list-todos', data: { } },
      { title: 'Listar Todos Admin', link: '/pages/tramites/tramites-list-todos-admin', data: { } },
    ],
  },
  {
    title: 'Reportes',
    icon: 'trending-up-outline',
    link: '/pages/reportes',
    data: { },
    children: [
      { title: 'Número de Guías', link: '/pages/reportes/numero-guias', data: { } },
      { title: 'Guías por Kilo', link: '/pages/reportes/numero-guias-kilos', data: { } },
      { title: 'Número de Inspecciones', link: '/pages/reportes/numero-inspecciones', data: { } },
      { title: 'Número de Intercepciones', link: '/pages/reportes/numero-intercepciones', data: { } },
      { title: 'Número de Retenciones', link: '/pages/reportes/numero-retenciones', data: { } },
      { title: 'Descargar Reporte', link: '/pages/reportes/descargar-reporte', data: { } },
      { title: 'Kilos Declarados', link: '/pages/reportes/kilos-declarados', data: { } },
      { title: 'Kilos Retenidos', link: '/pages/reportes/kilos-retenidos', data: { } },
      { title: 'Kilos Inspeccionados', link: '/pages/reportes/kilos-inspeccionados', data: { } },
    ],
  },
  {
    title: 'Administración',
    icon: 'options-2-outline',
    link: '/pages/administracion',
    data: { },
    children: [
      { title: 'Usuarios', link: '/pages/administracion/usuarios', data: { } },
      { title: 'Roles', link: '/pages/administracion/roles', data: { } },
      { title: 'Permisos', link: '/pages/administracion/permisos-roles', data: { } },
      { title: 'Seguridad y Auditoría', link: '/pages/administracion/seguridad-auditoria', data: { } },
      { title: 'Cambiar contraseña', link: '/pages/administracion/cambiar-contrasena', data: { } },
      /* { title: 'Registro de dispositivos', link: '/pages/administracion/registro-dispositivos', data: { } }, */
    ],
  },
  {
    title: 'Ayuda',
    icon: 'question-mark-circle-outline',
    link: '/pages/ayuda',
    children: [
      { title: 'Carga Masiva', link: '/pages/ayuda/carga-masiva', data: {} },
      { title: 'Productos Inórganicos', link: '/pages/ayuda/productos-inorganicos', data: {} },
    ],
  },
  /* {
    title: 'E-commerce',
    icon: 'shopping-cart-outline',
    link: '/pages/dashboard',
    home: true,
  },
  {
    title: 'IoT Dashboard',
    icon: 'home-outline',
    link: '/pages/iot-dashboard',
  },
  {
    title: 'FEATURES',
    group: true,
  },
  {
    title: 'Layout',
    icon: 'layout-outline',
    children: [
      {
        title: 'Stepper',
        link: '/pages/layout/stepper',
      },
      {
        title: 'List',
        link: '/pages/layout/list',
      },
      {
        title: 'Infinite List',
        link: '/pages/layout/infinite-list',
      },
      {
        title: 'Accordion',
        link: '/pages/layout/accordion',
      },
      {
        title: 'Tabs',
        pathMatch: 'prefix',
        link: '/pages/layout/tabs',
      },
    ],
  },
  {
    title: 'Forms',
    icon: 'edit-2-outline',
    children: [
      {
        title: 'Form Inputs',
        link: '/pages/forms/inputs',
      },
      {
        title: 'Form Layouts',
        link: '/pages/forms/layouts',
      },
      {
        title: 'Buttons',
        link: '/pages/forms/buttons',
      },
      {
        title: 'Datepicker',
        link: '/pages/forms/datepicker',
      },
    ],
  },
  {
    title: 'UI Features',
    icon: 'keypad-outline',
    link: '/pages/ui-features',
    children: [
      {
        title: 'Grid',
        link: '/pages/ui-features/grid',
      },
      {
        title: 'Icons',
        link: '/pages/ui-features/icons',
      },
      {
        title: 'Typography',
        link: '/pages/ui-features/typography',
      },
      {
        title: 'Animated Searches',
        link: '/pages/ui-features/search-fields',
      },
    ],
  },
  {
    title: 'Modal & Overlays',
    icon: 'browser-outline',
    children: [
      {
        title: 'Dialog',
        link: '/pages/modal-overlays/dialog',
      },
      {
        title: 'Window',
        link: '/pages/modal-overlays/window',
      },
      {
        title: 'Popover',
        link: '/pages/modal-overlays/popover',
      },
      {
        title: 'Toastr',
        link: '/pages/modal-overlays/toastr',
      },
      {
        title: 'Tooltip',
        link: '/pages/modal-overlays/tooltip',
      },
    ],
  },
  {
    title: 'Extra Components',
    icon: 'message-circle-outline',
    children: [
      {
        title: 'Calendar',
        link: '/pages/extra-components/calendar',
      },
      {
        title: 'Progress Bar',
        link: '/pages/extra-components/progress-bar',
      },
      {
        title: 'Spinner',
        link: '/pages/extra-components/spinner',
      },
      {
        title: 'Alert',
        link: '/pages/extra-components/alert',
      },
      {
        title: 'Calendar Kit',
        link: '/pages/extra-components/calendar-kit',
      },
      {
        title: 'Chat',
        link: '/pages/extra-components/chat',
      },
    ],
  },
  {
    title: 'Maps',
    icon: 'map-outline',
    children: [
      {
        title: 'Google Maps',
        link: '/pages/maps/gmaps',
      },
      {
        title: 'Leaflet Maps',
        link: '/pages/maps/leaflet',
      },
      {
        title: 'Bubble Maps',
        link: '/pages/maps/bubble',
      },
      {
        title: 'Search Maps',
        link: '/pages/maps/searchmap',
      },
    ],
  },
  {
    title: 'Charts',
    icon: 'pie-chart-outline',
    children: [
      {
        title: 'Echarts',
        link: '/pages/charts/echarts',
      },
      {
        title: 'Charts.js',
        link: '/pages/charts/chartjs',
      },
      {
        title: 'D3',
        link: '/pages/charts/d3',
      },
    ],
  },
  {
    title: 'Editors',
    icon: 'text-outline',
    children: [
      {
        title: 'TinyMCE',
        link: '/pages/editors/tinymce',
      },
      {
        title: 'CKEditor',
        link: '/pages/editors/ckeditor',
      },
    ],
  },
  {
    title: 'Tables & Data',
    icon: 'grid-outline',
    children: [
      {
        title: 'Smart Table',
        link: '/pages/tables/smart-table',
      },
      {
        title: 'Tree Grid',
        link: '/pages/tables/tree-grid',
      },
    ],
  },
  {
    title: 'Miscellaneous',
    icon: 'shuffle-2-outline',
    children: [
      {
        title: '404',
        link: '/pages/miscellaneous/404',
      },
    ],
  },
  {
    title: 'Auth',
    icon: 'lock-outline',
    children: [
      {
        title: 'Login',
        link: '/auth/login',
      },
      {
        title: 'Register',
        link: '/auth/register',
      },
      {
        title: 'Request Password',
        link: '/auth/request-password',
      },
      {
        title: 'Reset Password',
        link: '/auth/reset-password',
      },
    ],
  }, */
];
